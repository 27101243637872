import { Component, OnInit, EventEmitter, Output, Input, OnDestroy, ViewChild, ElementRef, ViewChildren } from '@angular/core';
import { Issue, IssueStockInfo, Title } from 'src/app/Models/issue.model';
import { NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { AuthService } from '../../services/auth-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HistorySale } from 'src/app/Models/history-sale.model';
import { CustomValidator } from '../../shared/customValidator';
import { Router, NavigationStart, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { SearchService } from 'src/app/services/search.service';
import { environment } from 'src/environments/environment';
import { Sweetalert } from 'src/app/shared/sweetAlert';
import { DatePipe } from 'src/app/shared/pipes/date-formatter.pipe';
import { StockInfoDelivery, StockInfoItem } from 'src/app/Models/stockinfo.model';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-new-request-modal',
  templateUrl: './new-request-modal.component.html',
  styleUrls: ['./new-request-modal.component.css']
})
export class NewRequestModalComponent implements OnInit, OnDestroy {
  @ViewChildren('myInput') vc: ElementRef;
  @ViewChildren('issuesinputs') issuesInputs;
  // declarations
  requestForm: FormGroup;
  private datePipe = new DatePipe();

  @Input() readonlySearch = false;
  @Input() typeAction = 'reorder';
  @Input() pendingRequestId = '';
  @Input() titleCodif = '';
  @Input() quantityParam = '-1';
  @Input() motifParam = '';
  @Input() setTitle: Issue;
  @Input() changeLoader: Subject<boolean> = new Subject<boolean>();
  @Output() reorderObjOutput = new EventEmitter<any>();
  @Output() closeModalOutput = new EventEmitter<any>();
  @Output() soldQuantity = new EventEmitter<number>();
  @Input() nimAssortimentStatus = false;

  @Input() changeCodif: Subject<string> = new Subject<string>();

  @Input() popupTitle = '';

  @Input() showPreviousNext = false;
  @Output() nextObj = new EventEmitter<any>();
  @Output() previousObj = new EventEmitter<any>();

  stockInfoItems: StockInfoItem[] = [];
  stockLoading = false;
  showBtnSpinner = false;
  deliveryInfo: StockInfoDelivery = null;
  displayAnalysePdif = false;
  quantityToDisplay = '';

  private stockInfoItemSub: Subscription;
  private stockInfoDateSub: Subscription;
  private loaderSub: Subscription;

  get isService() {
    return this.typeAction === 'service';
  }

  get isForgotten() {
    return this.typeAction === 'forgotten';
  }

  get isReorder() {
    return this.typeAction === 'reorder';
  }

  publications: Issue[] = [];
  publicationsSelected: Issue[] = [];
  historys: HistorySale[] = [];
  searchSelectedItem: Issue;
  searchString: string;
  isDisabledPubSelect = true;

  maxIssueCount = 1;

  maxQuantityMessage = '';
  motivationVal = '00';
  historyDisabled = true;
  maxQuantity = false;
  title = 'Demande de service';
  msgPublications = 'Aucune parution sélectionnée';

  allDays = [
    { id: 1, name: 'Lundi', issues: [], saleAverage: 0 },
    { id: 2, name: 'Mardi', issues: [], saleAverage: 0 },
    { id: 3, name: 'Mercredi', issues: [], saleAverage: 0 },
    { id: 4, name: 'Jeudi', issues: [], saleAverage: 0 },
    { id: 5, name: 'Vendredi', issues: [], saleAverage: 0 },
    { id: 6, name: 'Samedi', issues: [], saleAverage: 0 },
    { id: 7, name: 'Dimanche', issues: [], saleAverage: 0 },
  ];

  days = [
    { id: 1, name: 'Lundi', issues: [], saleAverage: 0 },
    { id: 2, name: 'Mardi', issues: [], saleAverage: 0 },
    { id: 3, name: 'Mercredi', issues: [], saleAverage: 0 },
    { id: 4, name: 'Jeudi', issues: [], saleAverage: 0 },
    { id: 5, name: 'Vendredi', issues: [], saleAverage: 0 },
    { id: 6, name: 'Samedi', issues: [], saleAverage: 0 },
    { id: 7, name: 'Dimanche', issues: [], saleAverage: 0 },
  ];

  showPlafond(): boolean {
    return !environment.disablePlafond;
  }

  getIssueText(issue: Issue): string {
    if (!issue) {
      return '';
    }

    let text = `n° ${issue.issueNumber} <br/>`;
    text += `<span class='font-w-100 small'>MEV du ${this.datePipe.transform(issue.releaseDate, 'DD/MM/YY')}`;
    if (issue.returnDate) {
      text += ` au ${this.datePipe.transform(issue.returnDate, 'DD/MM/YY')}`;
    }
    if (issue.pdifInfo && issue.pdifInfo.tooOldDate) {
      text += `<br/>Trop vieux : ${this.datePipe.transform(issue.pdifInfo.tooOldDate, 'DD/MM/YY')}`;
    }
    text += '</span>';

    return text;
  }

  isIssueNeverTooOld(issue: Issue): boolean {
    if (!issue
      || !issue.pdifInfo) {
      return false;
    }

    const returnDate = moment(issue.returnDate);
    if (!returnDate.isValid()) {
      return false;
    }

    if (returnDate.isBefore(moment())) {
      return issue.pdifInfo.neverTooOld;
    }

    return true;
  }

  isStand(issue: Issue) {
    return this.isReorder && issue.standInfo && issue.standInfo.quantity > 1;
  }

  get hideDay(): boolean {
    if (this.typeAction !== 'service') {
      return true;
    }

    return !this.isDaily;
  }

  get isDaily(): boolean {
    if (!this.searchSelectedItem
      || !this.searchSelectedItem.title) {
      return false;
    }

    return this.searchSelectedItem.title.isDaily;
  }

  isFormValid(form: NgForm, motivation: string): boolean {

    if (this.showBtnSpinner) {
      return false;
    }
    if (this.typeAction === 'forgotten') {
      return this.publicationsSelected.length > 0;
    }
    if (this.typeAction === 'service' && this.searchSelectedItem) {

      if (motivation === '00') {
        return false;
      }

      if (this.isDaily) {
        const itemCount = this.days.findIndex(d => d.issues.findIndex(i => i.quantity !== undefined) >= 0) >= 0;
        return motivation !== '00' && itemCount;
      }

      const quantity = parseInt(form.value.txtQteInput, 0);
      if (isNaN(quantity) || quantity < 0) {
        return false;
      }

      let status = motivation !== '00';
      if (this.nimAssortimentStatus && !this.searchSelectedItem.titleOnly) {
        status = status && this.filterPublicationsByDay.length > 0;
      }

      return status;
    } else if (this.typeAction === 'reorder' && this.maxQuantityMessage.length) {
      return false;
    }

    return this.publicationsSelected.filter(e => e.quantity > 0 && (e.title.allowReorder || e.title.reorderAllowed)).length > 0;
  }

  isQuantityValid(form: NgForm): boolean {

    if (this.showBtnSpinner) {
      return false;
    }
    return this.typeAction === 'service';
  }

  constructor(
    private authService: AuthService,
    private ngbModalHistoryService: NgbModal,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private searchService: SearchService,
    private sweet: Sweetalert
  ) { }

  createGroup() {
    const group = this.fb.group({});
    group.addControl('txtQteInput', this.fb.control(this.quantityToDisplay || '', [Validators.required, CustomValidator.numberValidator]));
    group.addControl('chkMaxQuantity', this.fb.control(''));
    group.addControl('chkTypeService', this.fb.control(''));
    this.publications.forEach((item, index) => {
      group.addControl(index.toString(), this.fb.control('', [Validators.required, CustomValidator.numberValidator]));
    });
    return group;
  }


  ngOnInit() {

    if (this.typeAction === 'reorder') {
      this.title = 'Nouvelle commande de réassort';
    } else if (this.typeAction === 'forgotten') {
      this.title = 'Nouvelle demande d’ouverture de droits aux oubliés';
    } else if (this.popupTitle && this.popupTitle.length) {
      this.title = this.popupTitle;
    }

    if (this.publications.length) {
      this.requestForm = this.createGroup();
    } else {
      const group = this.fb.group({});
      group.addControl('txtQteInput', this.fb.control('', [Validators.required, CustomValidator.numberValidator]));
      group.addControl('chkMaxQuantity', this.fb.control(''));
      group.addControl('chkTypeService', this.fb.control(''));
      this.requestForm = group;
    }

    this.stockInfoItemSub = this.searchService.getStockUpdateListener()
      .subscribe(stockInfo => {
        this.stockLoading = false;
        if (!stockInfo) {
          return;
        }

        this.stockInfoItems = stockInfo;
        if (this.publications
          && this.publications.length
          && this.stockInfoItems.length) {
          this.publications.forEach(pub => {
            const item = this.stockInfoItems.find(i => i.id === pub.id);
            if (item) {
              pub.stockInfo = pub.stockInfo || {
                isNeverTooOld: false,
                hasStockAllowNull: false,
                hasStock: false
              };
              pub.stockInfo.isNeverTooOld = this.isIssueNeverTooOld(pub);
              pub.stockInfo.hasStockAllowNull = this.stockAvailable(pub.id, true);
              pub.stockInfo.hasStock = this.stockAvailable(pub.id);
            }
          });
        }
      });

    this.stockInfoDateSub = this.searchService.getDeliveryDateUpdateListener()
      .subscribe(delivery => {
        this.deliveryInfo = delivery;
      });

    this.loaderSub = this.changeLoader.subscribe(response => {
      this.showBtnSpinner = response;
    });
  }

  unsoldRate(history: HistorySale): string {
    if (!history || history.unsold === undefined || !history.supplied) {
      return '';
    }
    return `${((history.unsold / history.supplied) * 100).toFixed(0)}&nbsp;%`;
  }

  // on search select
  onPublicationSeleceted($event) {
    if (!$event) {
      return;
    }

    this.maxQuantity = false;
    if (this.publications
      && this.publications.length) {
      this.publications.forEach(pub => {
        pub.stockInfo = {
          isNeverTooOld: this.isIssueNeverTooOld(pub),
          hasStockAllowNull: false,
          hasStock: false
        };
      });
    }

    this.publicationsSelected = [];
    this.publications = $event;

    const selectedIssueId = this.route.snapshot.queryParams['id'];
    let quantity = this.route.snapshot.queryParams['q'];
    let motif = this.route.snapshot.queryParams['m'];
    if(this.quantityParam && this.quantityParam.length && this.quantityParam !== '-1') {
      quantity = this.quantityParam;
    }
    if(this.motifParam && this.motifParam.length) {
      motif = this.motifParam;
    }

    this.pendingRequestId = this.route.snapshot.queryParams['prid'] || this.pendingRequestId;

    const selectedDay = parseInt(this.route.snapshot.queryParams['d'], 0) || 0;

    this.days = this.allDays;
    this.motivationVal = '00';

    const selectedItem = this.publications.find(p => p.id === selectedIssueId && selectedIssueId.indexOf(p.titleCodif) === 0);
    if (selectedItem) {
      if (quantity > 0 && this.typeAction === 'reorder') {
        selectedItem.quantity = quantity;
      }

      if (this.typeAction === 'service') {
        if (motif) {
          this.motivationVal = motif;
        } else {
          this.displayAnalysePdif = true;
          this.motivationVal = '26';
        }
      }

      if (selectedDay) {
        this.days = this.allDays.filter(d => d.id === selectedDay);
      }
      this.publicationsSelected.push(selectedItem);
    } else if (this.typeAction === 'service') {
      if (motif && quantity) {
        this.motivationVal = motif;
        this.quantityToDisplay = quantity;
      }
    }

    if (this.isDaily) {
      this.days.forEach(day => {
        day.issues = this.publications.filter(e => (moment(e.releaseDate).locale('fr').weekday() + 1) === day.id);
      });
    } else {
      this.days.forEach(day => {
        day.issues = [];
      });
    }
    this.setHistoryForDailies();
    this.isDisabledPubSelect = false;
    this.requestForm = this.createGroup();

    // check stock info
    if (environment.disableStock || this.stockLoading) {
      return;
    }

    if (this.typeAction === 'reorder') {
      this.stockLoading = true;
      this.searchService.getStockInfo($event.map(i => i.id), this.authService.currentNim);
    }
  }

  get versionAssortiment(): string {
    if (environment.production) {
      return '';
    }
    if (!this.authService.isAdmin) {
      return '';
    }
    if (!this.nimAssortimentStatus) {
      return '';
    }

    let version = '';
    if (this.searchSelectedItem && this.searchSelectedItem.title
      && this.searchSelectedItem.title.assortiment
      && this.searchSelectedItem.title.assortiment.version) {
      version = `Matrice version : ${this.searchSelectedItem.title.assortiment.version}`;
    }
    return version;
  }

  get displaySupressService() {
    if (!this.searchSelectedItem) {
      return false;
    }

    if (this.nimAssortimentStatus) {
      const assortiment = this.searchSelectedItem.title.assortiment;
      return assortiment && assortiment.deleteAllowed;
    }
    const titleCodif = this.searchSelectedItem.titleCodif || (this.searchSelectedItem.title ? this.searchSelectedItem.title.codif : '');
    return !this.searchSelectedItem.isCPPAP && titleCodif.startsWith('0');
  }

  stockAvailable(id: string, allowNull: boolean = false): boolean {

    const item = this.publications.find(i => i.id === id);

    if (!item || !item.title) {
      return false;
    }

    const stock = this.getStockQuantity(id);

    if (stock === null || stock === undefined) {

      if (!allowNull) {
        return false;
      }

      return item.title.reorderAllowed;

    } else if (stock <= 0) {
      return false;
    }

    if (this.reorder24(id) && id.startsWith('0')) {
      return stock > environment.reaLimitQuantity;
    }
    return true;
  }

  stockUnavailable(id: string): boolean {
    const stock = this.getStockQuantity(id);
    if (stock === null || stock === undefined) {
      return false;
    }

    if (id.startsWith('0')) {
      return stock <= environment.reaLimitQuantity;
    }

    return false;
  }

  getStockQuantity(id: string): number {

    if (!this.stockInfoItems || !this.stockInfoItems.length) {
      return null;
    }

    const item = this.stockInfoItems.find(e => e.id === id);
    if (!item) {
      return null;
    }

    return item.quantity;
  }

  changeMaxQuantity(event) {
    if (!event) {
      return;
    }

    const target = event.currentTarget || event.target;
    if (!target) {
      return;
    }

    this.maxQuantity = target.checked;
  }

  changeMaxCountToggle(quantity) {
    if (!quantity) {
      return;
    }

    this.changeMaxCount(quantity);
  }

  changeMaxCount(count) {
    this.maxIssueCount = count;
    this.publicationsSelected = [];
    if (this.isDaily) {
      this.days.forEach(d => {
        if (d.issues && d.issues.length) {
          d.issues.forEach(i => i.quantity = undefined);
        }
      });
    }
    if (this.issuesInputs && this.issuesInputs.length) {
      this.issuesInputs.forEach(element => {
        element.nativeElement.value = '';
      });
    }
  }

  get filterPublicationsByDay(): Issue[] {
    if (!this.publications || !this.publications.length) {
      return [];
    }

    if (this.typeAction !== 'service') {
      return [];
    }

    return this.publications.slice(0, this.maxIssueCount);
  }

  onQuantityChange(event, issueNumber) {
    this.maxQuantityMessage = '';
    const quantity = parseInt(event.target.value.trim(), 0);
    if (isNaN(quantity)) {
      event.target.value = '';
      return;
    }

    const selectedItemIndex = this.publicationsSelected.findIndex(i => i.issueNumber === issueNumber);
    const itemIndex = this.publications.findIndex(i => i.issueNumber === issueNumber);
    if (itemIndex < 0) {
      return;
    }

    if (!quantity) {

      if (selectedItemIndex >= 0) {
        this.publicationsSelected.splice(selectedItemIndex, 1);
      }
      return;
    }

    let maxQuantity = environment.reorderMaxQuantity.item;
    const item = this.publications[itemIndex];

    // check stock and allowed quantity
    if (this.reorder24(item.id)) {
      const available = this.getStockQuantity(item.id) || 0;
      // tslint:disable-next-line:max-line-length
      this.maxQuantityMessage = `Votre commande est supérieure au seuil autorisé. Veuillez saisir une quantité inférieure à ${environment.reorderMaxQuantity.item} exemplaires.`;
      if (item.standInfo && item.standInfo.quantity > 1) {
        maxQuantity = environment.reorderMaxQuantity.lot;
        // tslint:disable-next-line:max-line-length
        this.maxQuantityMessage = `Votre commande est supérieure au seuil autorisé. Veuillez saisir une quantité inférieure à ${environment.reorderMaxQuantity.lot} lots.`;
      }

      if (available) {
        if (available < maxQuantity) {
          // tslint:disable-next-line:max-line-length
          this.maxQuantityMessage = `Votre commande est supérieure au stock disponible. Veuillez saisir une quantité inférieure à ${available} exemplaires.`;
        }
        maxQuantity = Math.min(maxQuantity, available);

        if (quantity > maxQuantity) {
          this.sweet.warning('Attention !', this.maxQuantityMessage);
          return;
        }
      }
      this.maxQuantityMessage = '';
    }

    if (selectedItemIndex < 0) {
      this.publications[itemIndex].quantity = quantity;
      this.publicationsSelected.push(this.publications[itemIndex]);
    } else {
      this.publicationsSelected[selectedItemIndex].quantity = quantity;
    }
  }

  onQuantityChangeForDay(event, day) {
    this.maxQuantityMessage = '';
    const quantity = parseInt(event.target.value.trim(), 0);
    if (isNaN(quantity)) {
      event.target.value = '';
    }

    const selectedDay = this.days.find(i => i.id === day);

    if (!selectedDay || !selectedDay.issues.length) {
      return;
    }

    const filtetedIssues = selectedDay.issues.slice(0, this.maxIssueCount);
    filtetedIssues.forEach(si => {

      if (isNaN(quantity)) {
        si.quantity = undefined;
      } else {
        si.quantity = quantity;
      }
    });
  }

  // on search select
  onIssueChecked(event) {
    if (!event || this.typeAction === 'service') {
      return;
    }

    if (this.typeAction === 'reorder') {
      this.publicationsSelected = [];
    }

    const selectedItemIndex = this.publicationsSelected.findIndex(i => i.issueNumber === event.target.value);
    const itemIndex = this.publications.findIndex(i => i.issueNumber === event.target.value);

    if (itemIndex < 0) {
      return;
    }

    if (event.target.checked
      && selectedItemIndex < 0) {
      this.publicationsSelected.push(this.publications[itemIndex]);
    } else if (!event.target.checked
      && selectedItemIndex >= 0) {
      this.publicationsSelected.splice(selectedItemIndex, 1);
    }

    this.setParutionsMessage();
  }

  // on search select
  onSearchSelectedItem($event) {
    if (!$event) {
      return;
    }
    this.maxQuantity = false;
    this.searchSelectedItem = $event;
  }

  // on post new reorder
  onSaveReorder(form: NgForm, event: any) {
    const commentaire = this.maxQuantity ? '29' : this.motivationVal;
    if (!this.isFormValid(form, commentaire)) {
      return;
    }

    const nim = this.authService.currentNim;
    const numberCopies = event.target.txtCopies ? parseInt(event.target.txtCopies.value, 0) : Number.NaN;
    const today = moment(new Date()).locale('fr').format('YYYY-MM-DD');

    const reorderObj: any[] = [];

    if (this.typeAction === 'reorder') {

      this.publicationsSelected.filter(e => (e.quantity || 0) > 0).forEach(e => {
        let quantity = e.quantity;
        const isStand = this.isStand(e);
        if (isStand) {
          quantity = e.quantity * e.standInfo.quantity;
        }
        const stockQuantity = this.getStockQuantity(e.id) || 0;
        reorderObj.push({
          'nim': nim,
          'pendingRequestId': this.pendingRequestId,
          'code': 'REA',
          'codif': e.title.titleCodif,
          'numeroParution': e.issueNumber || e.referenceId,
          'quantity': quantity,
          'standInfo': !isStand ? null : {
            codif: e.standInfo.codif,
            quantity: e.quantity
          },
          'date': today,
          'title': e.title.name,
          'provided': e.provided,
          'stockUnavailable': !stockQuantity
        });
      });
    } else if (this.typeAction === 'service') {

      let lostCA: number = null;
      if (commentaire === '27') {
        if (numberCopies > 0) {
          this.sweet.warning('Attention !', 'Vous souhaitez supprimer ce titre de votre rayon, merci de saisir une quantité à 0 !');
          return;
        }
        if (numberCopies === 0) {
          lostCA = this.historys.length ? this.historys.map(h => (h.sold || 0) * this.searchSelectedItem.price).reduce((a, b) => a + b) : 0;
          if (lostCA > 0) {
            this.soldQuantity.emit(lostCA);
          }
        } else {
          this.sweet.warning('Attention !', 'Afin de valider votre demande de suppression, vous devez saisir une quantité à 0 !');
          return;
        }
      } else if (this.displaySupressService && numberCopies === 0) {
        this.sweet.warning('Attention !', 'Afin de valider votre demande de suppression, vous devez choisir le motif “Suppression“ !');
        return;
      }

      const titleOnly = this.searchSelectedItem.titleOnly;
      if (!this.isDaily) {
        const codif = this.searchSelectedItem.title.codif;
        if (this.publications.length) {
          this.publications.slice(0, this.maxIssueCount).forEach(e => {
            reorderObj.push({
              'titleOnly': titleOnly,
              'nim': nim,
              'pendingRequestId': this.pendingRequestId,
              'maxQuantity': this.maxQuantity,
              'code': 'SER',
              'codif': e.title.titleCodif,
              'numeroParution': e.issueNumber,
              'quantity': numberCopies,
              'date': today,
              'commentaire': commentaire,
              'dayCode': '',
              'motivation': commentaire,
              'provided': this.searchSelectedItem.provided || null,
              'chrono': e.externalIssueId,
              'lostCA': lostCA,
              'canRemoveService': this.displaySupressService
            });
          });
        } else {
          reorderObj.push({
            'titleOnly': titleOnly,
            'nim': nim,
            'code': 'SER',
            'pendingRequestId': this.pendingRequestId,
            'maxQuantity': this.maxQuantity,
            'codif': codif,
            'quantity': numberCopies,
            'date': today,
            'commentaire': commentaire,
            'dayCode': '',
            'motivation': commentaire,
            'lostCA': lostCA,
            'provided': this.searchSelectedItem.provided || null,
            'canRemoveService': this.displaySupressService
          });
        }
      } else { // dailies only
        this.days.forEach(day => {
          const issues = day.issues.filter(d => d.quantity >= 0);
          if (!issues.length) {
            return;
          }
          issues.forEach(e => {
            reorderObj.push({
              'titleOnly': titleOnly,
              'nim': nim,
              'code': 'SER',
              'pendingRequestId': this.pendingRequestId,
              'codif': e.title.titleCodif,
              'numeroParution': e.issueNumber || e.referenceId,
              'quantity': e.quantity,
              'date': today,
              'commentaire': commentaire,
              'dayCode': day.id,
              'motivation': commentaire,
              'provided': this.searchSelectedItem.provided || null,
              'chrono': e.externalIssueId,
              'lostCA': lostCA,
              'canRemoveService': this.displaySupressService
            });
          });
        });
      }
    } else {

      this.publicationsSelected.forEach(e => {
        reorderObj.push({
          'nim': nim,
          'code': 'OUB',
          'codif': e.title.titleCodif,
          'numeroParution': e.issueNumber || e.referenceId,
          'date': today
        });
      });
    }

    this.showBtnSpinner = true;
    this.reorderObjOutput.emit(reorderObj);
  }

  goNext($event) {
    this.nextObj.emit($event);
  }

  goPrevious($event) {
    this.previousObj.emit($event);
  }

  setHistory($event) {
    if (!$event) {
      this.soldQuantity.emit(0);
      return;
    }

    this.historys = $event;
    if ($event.length > 0) {
      this.historyDisabled = false;

      if (this.searchSelectedItem
        && this.searchSelectedItem.title
        && this.searchSelectedItem.title.isDaily) {
        this.setHistoryForDailies();
        this.soldQuantity.emit(-2);
      } else {
        const lastSales = this.historys.filter(a => a.supplied > 0 && a.sold >= 0).slice(0, 3);
        if (lastSales.length) {
          const value = lastSales.map((a, i) => a.sold || 0).reduce((a, b) => a + b) / lastSales.length;
          this.soldQuantity.emit(value);
        }
      }
    } else {
      this.soldQuantity.emit(-1);
    }
  }

  setHistoryForDailies() {
    if (!this.isDaily) {
      return;
    }

    const onlyValidSales = this.historys.filter(a => a.sold > 0);

    this.days.forEach(day => {

      if (!day.issues.length) {
        return;
      }
      const lastSalesForDay = onlyValidSales.filter(d => {
        const mnt = moment(d.saleDate);
        return mnt.weekday() === day.id;
      }).slice(0, 3);
      if (lastSalesForDay.length) {
        day.saleAverage = Math.round(lastSalesForDay.map((a, i) => a.sold).reduce((a, b) => a + b) / lastSalesForDay.length);
      }
    });
  }

  // on open history modal and logic
  openHistoryModal(content) {
    this.ngbModalHistoryService.open(content, { size: 'lg' });
  }

  closeHistoryModal() {
    this.ngbModalHistoryService.dismissAll();
  }
  // -----
  closeModal() {
    this.closeModalOutput.emit();
    const url = this.router.url;
    const titleCodif = this.route.snapshot.paramMap.get('titleCodif');

    if (titleCodif != null) {
      this.location.back();
    }

    if (url.includes('/services')) {
      this.router.navigate(['/services']);
    }
    if (url.includes('/reorder')) {
      this.router.navigate(['/reorder']);
    }
    if (url.includes('/forgotten')) {
      this.router.navigate(['/forgotten']);
    }
  }

  reorder24(id: string): boolean {
    if (!this.stockInfoItems || !this.stockInfoItems.length) {
      return false;
    }

    const item = this.stockInfoItems.find(e => e.id === id);
    return item && item.reorder24;
  }

  get reorder24Available(): boolean {
    if (this.stockLoading) {
      return false;
    }

    if (!this.stockInfoItems) {
      return false;
    }
    const index = this.stockInfoItems.findIndex(e => e.reorder24);
    return index < 0 ? false : true;
  }

  get stockInfoAvailable(): boolean {
    if (this.stockLoading) {
      return false;
    }

    if (!this.stockInfoItems) {
      return false;
    }
    const index = this.stockInfoItems.findIndex(e => e.quantity > 0);
    return index < 0 ? false : true;
  }

  getQuantityInfo_Html(issue: Issue): string {
    if (!issue
      || ! issue.title) {
      return '';
    }

    if (issue.title.quantityMax) {
      return `<span><b>QUANTITÉ MAX</b> : ${issue.title.quantityMax}</span>`;
    }

    if (issue.title.quantityLimit) {
      return `<span><b>QUANTITÉ PLAFOND</b> : ${issue.title.quantityLimit}</span><br/><span class="small">sur n° en cours de vente</span>`;
    }

    return '';
  }

  getIPG_CPPAPHtml(issue: Issue): string {

    const cppapStartDate = new Date(environment.disableIGP_CPPAP.startDate);
    const disableIPG_CPPAP = !this.authService.isAdmin && (new Date() < cppapStartDate);

    if (disableIPG_CPPAP
      || !issue) {
      return '';
    }

    const codif = issue.titleCodif || issue.title.codif || issue.title.titleCodif;
    if (!(codif || '').startsWith('0')) {
      return '';
    }

    let htmlOffre = '';
    if (issue.title.assortiment
      && this.nimAssortimentStatus) {
      if (issue.title.assortiment.code !== 'H') {
        htmlOffre = '<span class="btn btn-sm btn-info btn-smaller m-l-5">Dans l‘offre</span>';
      } else {
        htmlOffre = '<span class="btn btn-sm btn-info btn-smaller m-l-5">Hors offre</span>';
      }
    }

    let htmlMotif = '';
    if (issue.title.assortiment
      && this.nimAssortimentStatus
      && (issue.title.assortiment.reason || '').length) {
      htmlMotif = `<span class="btn btn-sm btn-warning btn-smaller m-l-5">${issue.title.assortiment.reason}</span>`;
    }

    const category = issue.category || '';
    if (category.length) {
      return `<span class="btn btn-sm btn-danger btn-smaller">${category}</span>${htmlOffre}${htmlMotif}`;
    }

    if (issue.isPolitic === true) {
      return `<span class="btn btn-sm btn-danger btn-smaller">IPG</span>${htmlOffre}${htmlMotif}`;
    }

    if (issue.isCPPAP === true) {
      return `<span class="btn btn-sm btn-danger btn-smaller">CPPAP</span>${htmlOffre}${htmlMotif}`;
    }

    return `<span class="btn btn-sm btn-danger btn-smaller">NON CPPAP</span>${htmlOffre}${htmlMotif}`;
  }

  getPredictedDeliveryDate(): string {
    if (!this.deliveryInfo) {
      return '';
    }

    return this.deliveryInfo.message;
  }

  setParutionsMessage() {
    const nbr = this.publicationsSelected.length;
    if (nbr > 0) {
      this.msgPublications = 'Vous avez selectionné ' + (nbr > 1 ? nbr : 'une') + ' parution' + (nbr > 1 ? 's' : '');
    } else {
      this.msgPublications = 'Aucune parution sélectionnée';
    }
  }

  isSelected(value: Issue): boolean {
    if (this.publicationsSelected.indexOf(value) >= 0) {
      return true;
    }
    return false;
  }

  isDisabled(value: Issue): boolean {
    let disabledOp = false;

    if (!value.supplied) {
      disabledOp = true;
    } else if (value.pdifInfo) {

      if (!value.pdifInfo.returnAuthorized) {

        disabledOp = true;
      }
    }

    return disabledOp;
  }

  ngOnDestroy() {
    if (this.stockInfoDateSub) {
      this.stockInfoDateSub.unsubscribe();
    }
    if (this.stockInfoItemSub) {
      this.stockInfoItemSub.unsubscribe();
    }
    if (this.loaderSub) {
      this.loaderSub.unsubscribe();
    }
  }
}
