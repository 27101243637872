import { Component, NgModuleRef, OnDestroy, OnInit } from '@angular/core';
import { NgbDateStruct, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth-service';
import { SondageService } from 'src/app/services/sondage.service';
import { Sweetalert } from 'src/app/shared/sweetAlert';
import * as moment from 'moment';
import { SondageAdminItem, SondageDetail, SondageItem } from 'src/app/Models/sondage.model';
import { ExportToCsv } from 'export-to-csv';

@Component({
  selector: 'app-sondages',
  templateUrl: './sondages.component.html',
  styleUrls: ['./sondages.component.css']
})
export class SondagesComponent implements OnInit, OnDestroy {

  sondagesSubs: Subscription;
  statusSubs: Subscription;
  currentModal: NgbModalRef;

  sondages: SondageAdminItem[] = [];
  selectedSondage: SondageAdminItem;
  showSpinner = true;

  sondageType = '';
  sondageStart: NgbDateStruct;
  sondageEnd: NgbDateStruct;
  nims: string[];

  importErrorMessage = '';
  importSuccessMessage = '';

  today: NgbDateStruct  = { day: new Date().getDate(), month: new Date().getMonth() + 1, year: new Date().getFullYear()};

  get isAdmin(): boolean {
    return this.authService.isAdmin;
  }

  constructor(private ngbModalService: NgbModal,
    private sondageService: SondageService,
    private sweet: Sweetalert,
    private authService: AuthService) { }

  ngOnInit() {

    this.initSondage();

    this.sondagesSubs = this.sondageService.getSondageListUpdateListener()
      .subscribe(result => {
        this.sondages = result || [];
        this.showSpinner = false;
      });
    this.statusSubs = this.sondageService.getSondageCallbackUpdateListner()
      .subscribe(result => {
        this.showSpinner = false;
        if (result) {
          this.closeModal();
          this.loadData();
        } else {
          this.sweet.warning('Attention !', 'Nous n’avons pas pu donner suite à votre demande.');
        }
      });
    this.loadData();
  }

  initSondage() {
    this.selectedSondage = {
      id: null,
      start: '',
      status: 0,
      end: '',
      body: null,
      title: null,
      link: null,
      type: 0,
      yes: 0,
      no: 0,
      pending: 0,
      details: []
    };
  }

  sondageStatus(sondage: SondageAdminItem): number {
    if (!sondage) {
      return -1;
    }

    const end = moment(sondage.end);
    if (!end.isValid()
      || end.isBefore(moment(), 'day')) {
      return -1;
    }

    return sondage.status;
  }

  deleteSondage(item: SondageAdminItem) {
    if (this.showSpinner) {
      return;
    }
    this.showSpinner = true;
    this.sondageService.deleteSondage(this.authService.currentNim, item.id);
  }

  loadData() {
    this.showSpinner = true;
    this.sondageService.sondageList(this.authService.currentNim);
  }

  start(item: SondageAdminItem) {
    if (this.showSpinner) {
      return;
    }
    this.showSpinner = true;
    this.sondageService.changeStatus(this.authService.currentNim, item.id);
  }

  stop(item: SondageAdminItem) {
    if (this.showSpinner) {
      return;
    }
    this.showSpinner = true;
    this.sondageService.changeStatus(this.authService.currentNim, item.id);
  }

  exportDataToCSV() {
    if (!this.selectedSondage
      || !this.selectedSondage.details
      || !this.selectedSondage.details.length) {
      return;
    }
    const details = this.selectedSondage.details;
    const date = moment(this.selectedSondage.start);
    const options = {
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: false,
      showTitle: false,
      title: `pdif-sondage-${date.format('YYYY-MM-DD')}`,
      filename: `pdif-sondage-${date.format('YYYY-MM-DD')}`,
      useBom: true,
      useKeysAsHeaders: false
    };
    const csvExporter = new ExportToCsv(options);
    const data = details.map((v, i) => {
      return {
        Nim: v.nim,
        nil: v.nil,
        Nature: v.nature || '',
        telephone: v.phone || '',
        statut: this.getAnswerResponse(v)
      };
    });
    csvExporter.generateCsv(data);
  }

  ngOnDestroy() {
    this.closeModal();
    if (this.sondagesSubs) {
      this.sondagesSubs.unsubscribe();
    }
    if (this.statusSubs) {
      this.statusSubs.unsubscribe();
    }
  }

  saveSondage() {
    if (this.showSpinner) {
      return;
    }
    if (!this.selectedSondage) {
      return;
    }

    if (this.authService.isAdmin
      && this.sondageType !== '3'
    ) {
      // check if any nims selected
      if (!this.selectedSondage.details
        || !this.selectedSondage.details.length) {
          this.sweet.warning('Attention !', 'Merci de définir la liste de diffuseurs à cibler');
          return;
        }
    }

    if (!this.selectedSondage.body) {
      this.sweet.warning('Attention !', 'Merci de saisir une question');
      return;
    }

    if (!this.selectedSondage.title) {
      this.sweet.warning('Attention !', 'Merci de saisir un titre');
      return;
    }

    const sType = parseInt(this.sondageType, 0);
    if (!sType) {
      this.sweet.warning('Attention !', 'Merci de saisir un type');
      return;
    }

    if (!this.sondageStart) {
      this.sweet.warning('Attention !', 'Merci de saisir une date de début');
      return;
    }

    if (!this.sondageEnd) {
      this.sweet.warning('Attention !', 'Merci de saisir une date de fin');
      return;
    }

    this.showSpinner = true;
    const start = `${this.sondageStart.year}-${this.sondageStart.month}-${this.sondageStart.day}T00:00:00Z`;
    const end = `${this.sondageEnd.year}-${this.sondageEnd.month}-${this.sondageEnd.day}T00:00:00Z`;
    this.selectedSondage.start = start;
    this.selectedSondage.end = end;
    this.selectedSondage.type = sType;

    this.sondageService.addSondage(this.authService.currentNim, this.selectedSondage);
  }

  getAnswerResponse(item: SondageDetail): string {
    if (!item) {
      return '';
    }

    if (item.answer === false) {
      return 'Non';
    }

    if (item.answer === true) {
      return 'Oui';
    }

    return 'En attente';
  }

  onFileSelect(input: HTMLInputElement) {

    const files = input.files;
    if (files && files.length) {
      const fileToRead = files[0];
      const fileReader = new FileReader();
      fileReader.onloadend = (e) => {
        // import form CSV
        const textFromFileLoaded = e.target.result as string;
        const regexp = /^\d{7}$/i;
        const nims = textFromFileLoaded.split(/\r?\n/).map(line => {
          return line.replace('"', '').split(/,|;/ig).map(t => t.trim());
        }).reduce((a, b) => a.concat(b)).filter(t => regexp.test(t));
        if (nims.length === 0) {
          this.importErrorMessage = 'Le fichier ne contient pas de NIM sur 7 caractères.';
          input.value = '';
        } else {
          this.selectedSondage.details = [];
          nims.forEach(n => {
            this.selectedSondage.details.push({nim: n, nil: null, nature: null, actif: null, phone: null, answer: null});
          });
          this.importSuccessMessage = `Nous avons detecté ${nims.length} diffuseurs dans le fichier`;
        }
        };
      fileReader.readAsText(fileToRead, 'UTF-8');
    }
  }

  openModal(modal, index: number) {
    this.importSuccessMessage = '';
    this.importErrorMessage = '';
    if (index < 0) {
      this.initSondage();
      this.currentModal = this.ngbModalService.open(modal, { size: 'lg' });
      return;
    }
    this.selectedSondage = this.sondages[index];
    const stDate = moment(this.selectedSondage.start);
    const enDate = moment(this.selectedSondage.end);
    this.sondageStart = { day: stDate.date(), month: stDate.month() + 1, year: stDate.year()};
    this.sondageEnd = { day: enDate.date(), month: enDate.month() + 1, year: enDate.year()};
    this.sondageType = this.selectedSondage.type.toString();
    this.currentModal = this.ngbModalService.open(modal, { size: 'lg' });
  }

  closeModal() {
    this.importSuccessMessage = '';
    this.importErrorMessage = '';
    this.selectedSondage = null;
    if (this.currentModal) {
      this.currentModal.close();
      this.currentModal = null;
    } else {
      this.ngbModalService.dismissAll();
    }
  }
}
