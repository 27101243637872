import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { DashboardService } from 'src/app/services/dashboard.service';
import { AuthService } from 'src/app/services/auth-service';
import { Subscription } from 'rxjs';
import { Rupture } from 'src/app/Models/rupture.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PdifAccounts } from 'src/app/Models/user.model';

@Component({
  selector: 'app-rupture',
  templateUrl: './rupture.component.html',
  styleUrls: ['./rupture.component.css']
})
export class RuptureComponent implements OnInit, OnDestroy {

  @Input() typeAction = 'ruptureOfDay'; // recurringRupture | top10

  hideComponent = !this.authService.isStore;
  ruptures: Rupture[] = [];

  rupturesSubs: Subscription;
  rupturesRecSubs: Subscription;
  top10Subs: Subscription;

  autoReorderSubs: Subscription;

  storeChangeSubs: Subscription;
  isLoading = true;

  store: PdifAccounts;

  get displayParameters(): boolean {
    return !this.isLoading && this.typeAction === 'ruptureOfDay';
  }

  get displaySettings(): boolean {
    return this.displayParameters && !this.authService.isReadOnly();
  }

  constructor(private authService: AuthService,
    private ngbModalService: NgbModal,
    private dashboradService: DashboardService) { }

  ngOnInit() {
  
    this.store = this.authService.getPdifAccount;
    this.storeChangeSubs = this.authService.getStoreChangeListener()
      .subscribe(store => {
        if (store) {
          this.store = store;
          this.getRuptures();
        }
      });

    if (this.typeAction === 'ruptureOfDay') {
      this.autoReorderSubs = this.dashboradService.getSubscribeAutoListener()
      .subscribe(res => {
        this.isLoading = false;
        if (res) {
          this.store.autoReorder = !this.store.autoReorder;
        }
      });
      this.rupturesSubs = this.dashboradService.getRupturesListener()
        .subscribe((res: Rupture[]) => {
          this.ruptures = res || [];
          this.isLoading = false;
        },
          err => {
            this.ruptures = [];
            this.isLoading = false;
          });
    }

    if (this.typeAction === 'recurringRupture') {
      this.rupturesRecSubs = this.dashboradService.getRupturesRecListener()
        .subscribe((res: Rupture[]) => {
          this.ruptures = res || [];
          this.isLoading = false;
        },
          err => {
            this.ruptures = [];
            this.isLoading = false;
          });
    }

    if (this.typeAction === 'top10') {
      this.top10Subs = this.dashboradService.getTop10Listener()
        .subscribe((res: Rupture[]) => {
          this.ruptures = res || [];
          this.isLoading = false;
        },
          err => {
            this.ruptures = [];
            this.isLoading = false;
          });
    }
    this.getRuptures();
  }

  openModal(modal) {
    this.ngbModalService.open(modal);
  }

  showReorder(item: Rupture): boolean {

    if (this.typeAction === 'recurringRupture') {
      return false;
    }
    return item.hasStock && item.orderedSamples === 0;
  }

  showService(item: Rupture): boolean {

    if (this.typeAction === 'ruptureOfDay') {
      return false;
    }
    return !item.hasService;
  }

  closeModal() {
    this.ngbModalService.dismissAll();
  }
  // get Ruptures
  getRuptures() {

    if (this.hideComponent) {
      return;
    }

    this.isLoading = true;
    if (this.typeAction === 'ruptureOfDay') {
      this.dashboradService.getRuptures(this.authService.currentNim);
    } else if (this.typeAction === 'recurringRupture') {
      this.dashboradService.getRecurrentRuptures(this.authService.currentNim);
    } else {
      this.dashboradService.getTop10(this.authService.currentNim);
    }
  }

  autoReorder() {

    if (!this.authService.isStore
      || this.typeAction !== 'ruptureOfDay') {
      return;
    }

    this.isLoading = true;
    this.dashboradService.subscribeAutoReorder(this.authService.currentNim);
  }

  getTitle() {

    if (this.typeAction === 'ruptureOfDay') {
      return 'Vos ruptures du jour';
    } else if (this.typeAction === 'recurringRupture') {
      return 'Vos ruptures régulières';
    } else {
      return 'Top des incontournables du moment';
    }
  }

  ngOnDestroy() {
    if (this.rupturesSubs) {
      this.rupturesSubs.unsubscribe();
    }
    if (this.storeChangeSubs) {
      this.storeChangeSubs.unsubscribe();
    }
    if (this.top10Subs) {
      this.top10Subs.unsubscribe();
    }
    if (this.autoReorderSubs) {
      this.autoReorderSubs.unsubscribe();
    }
    if (this.rupturesRecSubs) {
      this.rupturesRecSubs.unsubscribe();
    }
  }

  scrollRight(element) {
    var container = this.getParentContainer(element);

    if(!container) {
      return;
    }

    const newLeft = container.scrollLeft + 450;
    container.scrollTo({ left: newLeft, behavior: 'smooth' });
  }

  scrollLeft(element) {
    var container = this.getParentContainer(element);

    if(!container) {
      return;
    }

    const newLeft = container.scrollLeft - 450;
    container.scrollTo({ left: newLeft, behavior: 'smooth' });
  }

  getParentContainer(element) {
    if (!element
      || !element.currentTarget
      || !element.currentTarget.parentElement
      || !element.currentTarget.parentElement.parentElement
      || element.currentTarget.parentElement.parentElement.className !== 'category-items') {
        return null;
      }
    return element.currentTarget.parentElement.parentElement;
  }
}
