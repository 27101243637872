import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject, Subscription } from 'rxjs';
import { Title } from 'src/app/Models/title.model';
import { AuthService } from 'src/app/services/auth-service';
import { PendingrequestService } from 'src/app/services/pendingrequest.service';
import { UpdateserviceService } from 'src/app/services/updateservice.service';
import { DtLanguages } from 'src/app/shared/dtLanguages';
import { GA } from 'src/app/shared/gAnalytics';
import * as moment from 'moment';
import { Sweetalert } from 'src/app/shared/sweetAlert';
import { environment } from 'src/environments/environment';
import { BaseupdateserviceComponent } from 'src/app/shared/baseupdateservice/baseupdateservice.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReorderService } from 'src/app/services/reorder.service';
import { SaveReorderStatus } from 'src/app/Models/savereorder.model';

@Component({
  selector: 'app-pendingrequest',
  templateUrl: './pendingrequest.component.html',
  styleUrls: ['./pendingrequest.component.css']
})
export class PendingrequestComponent extends BaseupdateserviceComponent implements OnInit, AfterViewInit, OnDestroy {

  private DONE_SERVICE_KEY_PREFIX = 'assort-service';
  // declarations
  private titlesSubs: Subscription;
  private removeSubs: Subscription;
  private saveReorderSubs: Subscription;
  showTableSpinner = true;
  showNoDataInfo = false;
  titles: Title[];

  type = 0;

  changeCodif: Subject<string> = new Subject<string>();
  protected titleCodif: string;
  pendingRequestId = '';

  // datatable initialisations
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;

  constructor(private pendingRequestService: PendingrequestService,
    protected updateService: UpdateserviceService,
    protected reorderService: ReorderService,
    protected authService: AuthService,
    private route: ActivatedRoute,
    protected ngbModalService: NgbModal,
    protected router: Router,
    protected ga: GA,
    protected sweet: Sweetalert,
    private dtlanguages: DtLanguages) { 
      super(updateService, authService, router, ngbModalService, ga, sweet);
    }

  ngOnInit() {
    this.type = parseInt(this.route.snapshot.queryParams.type) || 0;
    this.typeAction = this.type === 0 ? 'service' : 'reorder';
    
    super.ngOnInit();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      searching: true,
      responsive: true,
      lengthChange: true,
      ordering: true,
      columnDefs: [{
        'targets': [0],
        'orderable': false
      }],
      autoWidth: false,
      order: [1, 'desc'],
      language: this.dtlanguages.frLanguage()
    };

    this.saveSubs = this.reorderService.saveReorderRequestsUpdateListner()
      .subscribe(result => {
        this.showModalSpinner.next(false);
        if (result
          && result.status) {
          this.closeModal(false);
          //this.onBindServices();
          this.isProposalProcess = false;
          this.ga.createEvent('click', 'Demandes', 'Création Nouvelle Demande de réassort');

          this.goNext();
          // if (result.serviceQuantity) {
          //   // tslint:disable-next-line:max-line-length
          //   this.sweet.confirmDialog('Félicitations !', 'Votre demande de réassort est enregistrée, souhaitez-vous installer le titre ?', (error: Error, answer: any): void => {

          //     if (answer) {
          //       this.checkReorderProposal(result);
          //     }
          //   });
          // }
        } else {
          this.sweet.error('Attention !', 'Une erreur est survenue lors de la validation de votre demande, veuillez contacter le support.');
        }
      });

    this.saveSubs = this.UpdateserviceRequest.getSaveUpdateListener()
          .subscribe(result => {
            this.showModalSpinner.next(false);
            if (result && result.status) {
              //this.closeModal(false);
              //this.onBindServices();
              this.isProposalProcess = false;
              this.gaEvent();
              //this.checkServiceProposal(result);
              this.goNext();
            }  else {
              this.sweet.error('Attention !', 'Une erreur est survenue lors de la validation de votre demande, veuillez contacter le support.');
            }
          });

    this.titlesSubs = this.pendingRequestService.getPendingRequestListener()
      .subscribe((servicesResults: Title[]) => {
        this.showTableSpinner = false;
        this.titles = servicesResults || [];
        this.showNoDataInfo = this.titles.length === 0;

        if(this.titles.length) {
          this.rerender();
        }
      });

      this.removeSubs = this.pendingRequestService.getRemoveListener()
      .subscribe(res => {
        if (res) {
          this.onBindServices();
        } else {
          this.showTableSpinner = false;
          this.sweet.warning('Attention !', 'Une erreur s‘est produite, nous vous invitons à essayer plus tard.');
        }
      });

      this.onBindServices();
  }

  closeAndRefresh() {
    this.closeModal(false);
    this.onBindServices();
  }

  protected checkReorderProposal(proposal: SaveReorderStatus) {
    if (!proposal || !proposal.status || !proposal.codif || !proposal.codif.length) {
      return;
    }
    this.closeModal();
    this.sweet.confirmDialog('Proposition de titre !', '', (s, e) => {
        if (!s && !e) {
          return;
        }
        if (e) {
          // redirect to service
          // const url = `services/titles/${proposal.codif}`;
          // const queryParam = { q: proposal.serviceQuantity, m: proposal.motif};
          // this.router.navigate([url], { queryParams: queryParam });

          this.isProposalProcess = true;
          this.titleCodif = proposal.codif;
          this.quantityParam = proposal.serviceQuantity.toString();
          this.motifParam = proposal.motif;
          this.openModal(this.modalService);
        }
      }, null, 'success');
  }

  getButtonTitle(codif) {
    return this.type === 0 ? 'SERVICE' : 'RÉASSORT';
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  // bind Services
  onBindServices() {

    this.showTableSpinner = true;
    this.showNoDataInfo = false;
    const nim = this.authService.currentNim;

    const type = this.route.snapshot.queryParams.type || 0;
    this.pendingRequestService.getPFPPendingRequests(nim, type);

  }

  deleteItem(id: string) {
    if (this.showTableSpinner) {
      return;
    }
    this.sweet.confirmDialog('Attention !', 'Êtes-vous sûr de vouloir supprimer cette ligne ?',
      (error: Error, result: any): void => {
        if (result) {
          this.showTableSpinner = true;
          this.pendingRequestService.removePendingRequest(this.authService.currentNim, id);
        }
      });
  }

  // rerender the datatable
  rerender() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
    });
    this.dtTrigger.next();
  }

  issueText(title: Title): string {

    if (!title) {
      return '';
    }

    let textCodif = title.codif;

    if (title.dayCode) {
      const day = environment.days.filter(d => d.id === title.dayCode);
      if (day.length) {
        textCodif += `&nbsp;${day[0].name}`;
      }
    }

    return `${title.title}&nbsp;<span class="text-muted">Codif : ${textCodif}</span>`;
  }

  serviceForTitle(item: Title) {
    if (!item) {
      return;
    }

    this.titleCodif = item.codif;
    this.pendingRequestId = item.pendingRequestId;
    this.openModal(this.modalService);
  }

  goNext() {
    this.checkCurrentItemWithActiveSorting(1);
  }

  goPrevious() {
    this.checkCurrentItemWithActiveSorting(-1);
  }

  saveRequest($event) {
    if (!$event) {
      this.showModalSpinner.next(false);
      return;
    }

    if (this.typeAction === 'service') {
      this.onSaveRequest($event);
    } else {
      const initialQuantity = $event[0].provided;
      const eventQuantity = parseInt($event[0].quantity, 0) || 0;
      let checkQuantity = initialQuantity > 0;
      if (checkQuantity) {
        checkQuantity = eventQuantity > 2 * initialQuantity && eventQuantity > 10;
      } else {
        checkQuantity = eventQuantity > 10;
      }
  
      // tslint:disable-next-line:max-line-length
      const reorderMessage = 'Vous êtes sur le point de commander ' + eventQuantity + ' exemplaires de “' + $event[0].title + '” n° ' + $event[0].numeroParution + ', souhaitez-vous continuer ?';
      let message = checkQuantity ? 'La quantité commandée est importante. Souhaitez-vous continuer ?' : reorderMessage;
  
      const existing = this.titles.find(t => t.codif === $event[0].codif
                            && !t.delivred
                            && t.numeroParution === $event[0].numeroParution);
  
      if (existing && existing.quantity) {
        // tslint:disable-next-line:max-line-length
        message = `Vous avez déjà une commande en cours de ${existing.quantity} ex pour la codif ${existing.codif} N° ${existing.numeroParution}<br/>Souhaitez vous valider une nouvelle commande ?`;
      }
  
      this.sweet.confirmDialog('Attention !', message, (error: Error, result: any): void => {
  
        if (result) {
          this.reorderService.addNewReorder($event);
        } else {
          this.showModalSpinner.next(false);
        }
      });
    }
  }

  private checkCurrentItemWithActiveSorting(position: number) {

    // get active sorting
    const order = this.dtElement['dt'].order()[0];
    const orderDirection = order[1] === 'desc' ? 0 : 1;
    const strOrder = order[0] || 0;
    let orderIndex = parseInt(strOrder.toString(), 0);
    
    this.titles
      .sort((a, b) => {
        const aDate = moment(a.dateOrder);
        const bDate = moment(b.dateOrder);
        return orderDirection ? (aDate.isSameOrBefore(bDate) ? 1 : -1) : (bDate.isSameOrBefore(aDate) ? 1 : -1);
      });

    
    if (orderIndex === 2)
    {
      const field = 'title';

      this.titles
        .sort((a, b) => {
          const aVal = a[field];
          const bVal = b[field];
          return orderDirection ? aVal.localeCompare(bVal) : bVal.localeCompare(aVal);
        });
    }

    const itemIndex = this.titles.findIndex(e => e.codif === this.titleCodif);
    if (itemIndex >= this.titles.length) {
      return;
    }

    const item = this.titles[itemIndex + position];

    if (!item) {
      return;
    }

    this.titleCodif = item.codif;
    this.pendingRequestId = item.pendingRequestId;
    this.changeCodif.next(this.titleCodif);
  }

  ngOnDestroy() {
    if (this.titlesSubs) {
      this.titlesSubs.unsubscribe();
    }
    if (this.dtTrigger) {
      this.dtTrigger.unsubscribe();
    }
    if (this.removeSubs) {
      this.removeSubs.unsubscribe();
    }
    if (this.saveReorderSubs) {
      this.saveReorderSubs.unsubscribe();
    }
  }

}
