import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { StoreService } from '../services/store.service';
import { Store, DataItem } from '../Models/store.model';
import { PendingChange } from '../Models/pendingchanges/pendingchange.model';
import { Linear } from '../Models/specifications/linear.model';
import { Sweetalert } from '../shared/sweetAlert';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { NewStatment, LineStatement } from '../Models/newStatment.model';
import { SocialReasonComponent } from './social-reason/social-reason.component';
import { DepositComponent } from './deposit/deposit.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-pdv',
  templateUrl: './pdv.component.html',
  styleUrls: ['./pdv.component.css']
})
export class PdvComponent implements OnInit, OnDestroy {

  @ViewChild('tabset') tabset;

  constructor(protected storeService: StoreService, protected ngbModalService: NgbModal,
    protected sweet: Sweetalert, private route: ActivatedRoute,) { }
  store: Store;
  changes: NewStatment[];
  private storeAndPendingChanges: Subscription;
  private storeRefreshSubs: Subscription;
  isLoading = false;
  currentJustify = 'start';
  isErrorLoading = false;

  get displayRib() {
    if (!this.store)
      return false;
    
    if (!this.store.ibanInfo)
      return false;
    
    return this.store.ibanInfo.status >= 0;
  }

  ngOnInit() {
    this.storeAndPendingChanges = this.storeService.getstoreAndPendingUpdateListner()
      .subscribe(response => {
        if (!response) {
          this.isErrorLoading = true;
          return;
        }
        this.store = response.store;
        this.changes = response.changes;
        this.isLoading = false;
        setTimeout(() => {
          this.goToTab();
        }, 600);
      },
        err => {
          this.isLoading = false;
        });
    this.storeRefreshSubs = this.storeService.getStoreUpdateListner()
        .subscribe(response => {
          this.isLoading = false;
          if (!response) {
            return;
          }
          this.store = response;
          this.goToTab();
        },
          err => {
            this.isLoading = false;
          });
    
    this.getStoreAndPendingChanges();
  }

  goToTab() {
    var activeTab = this.route.snapshot.queryParams['screen'] || 'tabADMIN';
    if (!this.displayRib && activeTab === 'tabRIB') {
      activeTab = 'tabADMIN';
    }
    if (this.tabset.activeId === activeTab) {
      return;
    }
    this.tabset.select(activeTab);
  }

  refreshStore($event: Store) {
    this.isLoading = true;
    this.storeService.getStore()
  }

  save($event: LineStatement[]) {
    if (!$event) {
      this.getStoreAndPendingChanges(true);
      return;
    }

    this.isLoading = true;
    this.sweet.confirmDialog('Attention !', 'Êtes-vous sûr de valider les modifications?', (error: Error, result: any): void => {
      if (!result && !error) {
        this.isLoading = false;
        return;
      }
      if (error) {
        this.isLoading = false;
        this.sweet.warning('Attention !', 'Vos modifications n’ont pas pu être enregistrer, veuillez réessayer plus tard.');
        return;
      }
      this.storeService.saveChanges($event)
        .subscribe(response => {
          this.isLoading = false;
          if (response.result) {
            this.sweet.success('Succès !', 'Vos modifications ont bien été enregistrées.');
            $event = [];
            this.getStoreAndPendingChanges(true);
            this.ngbModalService.dismissAll();
          }
        },
          err => {
            this.isLoading = false;
            this.sweet.warning('Attention !', 'Vos modifications n’ont pas pu être enregistrer, veuillez réessayer plus tard.');
          });
    });
  }

  getStoreAndPendingChanges(forceReload = false) {

    if (!forceReload && this.store != null && this.changes != null) {
      return;
    }

    this.isLoading = true;
    this.storeService.getStoreWithPendingChanges();
  }

  refreshPage() {
    window.location.reload();
  }
  ngOnDestroy() {
    if (this.storeAndPendingChanges) {
      this.storeAndPendingChanges.unsubscribe();
    }
    if (this.storeRefreshSubs) {
      this.storeRefreshSubs.unsubscribe();
    }
  }
}

