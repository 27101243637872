import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Subject, forkJoin } from 'rxjs';
import { Store, StoreAndPendingChange } from '../Models/store.model';
import { TurnoverV2 } from '../Models/turnover.model';
import { Tour } from '../Models/tour.model';
import { AuthService } from './auth-service';
import { SignupStat, ActionStat, WarehouseDetailStat } from '../Models/signupStat.model';
import { NewStatment, PostStatementAction } from '../Models/newStatment.model';
import { Warehouse } from '../Models/warehouse.model';
import { HelpDeskAccount, HelpDeskAccountMutation } from '../Models/user.model';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  constructor(private http: HttpClient, private authService: AuthService) { }
  private helpdeskUpdate = new Subject<HelpDeskAccount>();
  private storeUpdate = new Subject<Store>();
  private changePasswordUpdate = new Subject<boolean>();
  private signupStatsUpdate = new Subject<SignupStat[]>();
  private actionStatsUpdate = new Subject<ActionStat>();
  private warehouseStatsUpdate = new Subject<WarehouseDetailStat[]>();
  private heldeskActionUpdate = new Subject<any>();
  private storeAndPendingUpdate = new Subject<StoreAndPendingChange>();
  private turnoverUpdate = new Subject<TurnoverV2>();
  private toursUpdate = new Subject<Tour[]>();
  private warehousesUpdate = new Subject<Warehouse[]>();
  private createAccountUpdate = new Subject<boolean>();

  private ibanCheckUpdate = new Subject<number>();
  private ibanCheckWithMessageUpdate = new Subject<number>();

  private storesUpdate = new Subject<Store[]>();

  private statementsUpdate = new Subject<NewStatment[]>();

  getStoresUpdateListner() {
    return this.storesUpdate.asObservable();
  }

  getCreateAccountUpdateListner() {
    return this.createAccountUpdate.asObservable();
  }

  getStatementsUpdateListner() {
    return this.statementsUpdate.asObservable();
  }

  getStoreUpdateListner() {
    return this.storeUpdate.asObservable();
  }

  getHelpdeskUpdateListner() {
    return this.helpdeskUpdate.asObservable();
  }

  getChangePasswordUpdateListner() {
    return this.changePasswordUpdate.asObservable();
  }

  getSignupStatsListner() {
    return this.signupStatsUpdate.asObservable();
  }

  getActionStatsListner() {
    return this.actionStatsUpdate.asObservable();
  }

  getWarehouseStatsListner() {
    return this.warehouseStatsUpdate.asObservable();
  }

  get isHelpdesk(): boolean {
    return this.authService.isHelpdesk;
  }

  getheldeskActionUpdateListner() {
    return this.heldeskActionUpdate.asObservable();
  }

  getTurnoverUpdateListner() {
    return this.turnoverUpdate.asObservable();
  }

  getstoreAndPendingUpdateListner() {
    return this.storeAndPendingUpdate.asObservable();
  }

  getToursUpdateListner() {
    return this.toursUpdate.asObservable();
  }

  getWarehousesUpdateListner() {
    return this.warehousesUpdate.asObservable();
  }

  private loadStore(forceRefresh = false) {
    var url = `${environment.APIURL}/api/v1/stores?nim=${this.authService.currentNim}`;
    if(forceRefresh) url += `&ts=${new Date().getTime()}`;
    return this.http.get<{ result: Store }>(url);
  }

  getStore(forceRefresh = false) {
    this.loadStore(forceRefresh)
    .subscribe(response => {
      if (response) {
        this.storeUpdate.next(response.result);
      }
    });
  }

  getWarehouses() {
    this.http.get<{ result: Warehouse[] }>(environment.APIURL + '/api/v1/warehouses')
      .subscribe(response => {
        if (response) {
          this.warehousesUpdate.next(response.result);
        } else {
          this.warehousesUpdate.next(null);
        }
      }, error => {
        this.warehousesUpdate.next(null);
      });
  }

  getWarehouseTours() {
    this.http.get<{ result: Tour[] }>(environment.APIURL + '/api/v1/warehouses/' + this.authService.currentNim + '/tours')
      .subscribe(response => {
        if (response) {
          this.toursUpdate.next(response.result);
        } else {
          this.toursUpdate.next(null);
        }
      }, error => {
        this.toursUpdate.next(null);
      });
  }

  getWarehouseStores() {
    this.http.get<{ result: Store[] }>(environment.APIURL + '/api/v1/warehouses/' + this.authService.currentNim + '/stores')
      .subscribe(response => {
        if (response) {
          this.storesUpdate.next(response.result);
        } else {
          this.storesUpdate.next(null);
        }
      }, error => {
        this.storesUpdate.next(null);
      });
  }

  getStoreWithPendingChanges(forceRefresh = false) {
    forkJoin([this.loadStore(forceRefresh), this.loadChanges()])
      .subscribe(results => {

        if (!results || !results.length) {
          return;
        }
        const data = new StoreAndPendingChange();

        if (results.length > 1) {
          data.store = results[0].result;
          data.changes = results[1].result;
        }

        this.storeAndPendingUpdate.next(data);
      },
        err => {
          this.storeAndPendingUpdate.next(null);
        });
  }

  getTurnover() {
    this.http.get<{ result: TurnoverV2 }>(environment.APIURL + '/api/v3/metrics/store/' + this.authService.currentNim + '/turnover')
      .subscribe(response => {
        if (response) {
          this.turnoverUpdate.next(response.result);
        } else {
          this.turnoverUpdate.next(null);
        }
      }, err => {
        this.turnoverUpdate.next(null);
      });
  }

  getSignupStats(from: string, to: string) {
    this.http.get<{ result: SignupStat[] }>(environment.APIURL + `/api/v1/kpi/light?start=${from}&end=${to}&nims=`)
      .subscribe(response => {
        if (response) {
          this.signupStatsUpdate.next(response.result);
        } else {
          this.signupStatsUpdate.next(null);
        }
      }, erro => {
        this.signupStatsUpdate.next(null);
      });
  }

  getWarehouseStats(from: string, to: string) {
    const url = `${environment.APIURL}/api/v1/kpi/warehouses?start=${from}&end=${to}&nims=`;
    this.http.get<{ result: WarehouseDetailStat[] }>(url)
      .subscribe(response => {
        if (response) {
          this.warehouseStatsUpdate.next(response.result);
        } else {
          this.warehouseStatsUpdate.next(null);
        }
      }, erro => {
        this.warehouseStatsUpdate.next(null);
      });
  }

  getActionStats(from: string, to: string) {
    this.http.get<{ result: ActionStat }>(environment.APIURL + `/api/v1/kpi/actions?start=${from}&end=${to}&nims=`)
      .subscribe(response => {
        if (response) {
          this.actionStatsUpdate.next(response.result);
        } else {
          this.actionStatsUpdate.next(null);
        }
      }, erro => {
        this.actionStatsUpdate.next(null);
      });
  }

  searchNim(nim: string) {
    this.http.get<{ result: HelpDeskAccount }>(environment.APIURL + '/api/v4/heldesk?nim=' + nim)
      .subscribe(response => {
        if (response) {
          this.helpdeskUpdate.next(response.result);
        } else {
          this.helpdeskUpdate.next(null);
        }
      }, erro => {
        this.helpdeskUpdate.next(null);
      });
  }

  changePasswordByAdmin(nim: string, password: string) {
    const url = `${environment.APIURL}/api/v4/admin/users/changepassword?nim=${nim}`;
    this.http.post<{ result: boolean }>(url, {password: password})
      .subscribe(response => {
        if (response) {
          this.changePasswordUpdate.next(response.result);
        } else {
          this.changePasswordUpdate.next(null);
        }
      }, erro => {
        this.changePasswordUpdate.next(null);
      });
  }

  changeEmailByAdmin(nim: string, email: string) {
    const url = `${environment.APIURL}/api/v4/admin/users/changepassword?nim=${nim}`;
    this.http.post<{ result: boolean }>(url, {email: email})
      .subscribe(response => {
        if (response) {
          this.changePasswordUpdate.next(response.result);
        } else {
          this.changePasswordUpdate.next(null);
        }
      }, erro => {
        this.changePasswordUpdate.next(null);
      });
  }

  changeAccountingEmailByAdmin(nim: string, userInfo: HelpDeskAccountMutation) {
    const url = `${environment.APIURL}/api/v4/heldesk/setaccountingemail?nim=${nim}`;
    this.http.post<{ result: boolean }>(url, userInfo)
      .subscribe(response => {
        if (response) {
          this.changePasswordUpdate.next(response.result);
        } else {
          this.changePasswordUpdate.next(null);
        }
      }, erro => {
        this.changePasswordUpdate.next(null);
      });
  }

  unsetAccountingEmailByAdmin(nim: string) {
    const url = `${environment.APIURL}/api/v4/heldesk/unsetaccountingemail?nim=${nim}`;
    this.http.post<{ result: boolean }>(url, {})
      .subscribe(response => {
        if (response) {
          this.changePasswordUpdate.next(response.result);
        } else {
          this.changePasswordUpdate.next(null);
        }
      }, erro => {
        this.changePasswordUpdate.next(null);
      });
  }

  createAccountByAdmin(nim: string, email: string, password: string, mutationDate: string) {
    const url = `${environment.APIURL}/api/v4/heldesk/createaccount?nim=${nim}`;
    this.http.post<{ result: boolean }>(url, {email: email, password: password, mutationDate: mutationDate})
      .subscribe(response => {
        if (response) {
          this.createAccountUpdate.next(response.result);
        } else {
          this.createAccountUpdate.next(false);
        }
      }, erro => {
        this.createAccountUpdate.next(false);
      });
  }

  sendMail(nim: string) {
    this.http.get<{ result: boolean }>(environment.APIURL + '/api/v4/heldesk/resend/validation?nim=' + nim)
      .subscribe(response => {
        if (response) {
          this.heldeskActionUpdate.next({ status: response.result, nim, action: 'send' });
        } else {
          this.heldeskActionUpdate.next(null);
        }
      }, erro => {
        this.heldeskActionUpdate.next(null);
      });
  }

  resetPassword(nim: string) {
    this.http.get<{ result: boolean }>(environment.APIURL + '/api/v4/heldesk/resend/password?nim=' + nim)
      .subscribe(response => {
        if (response) {
          this.heldeskActionUpdate.next({ status: response.result, nim, action: 'reset' });
        } else {
          this.heldeskActionUpdate.next(null);
        }
      }, erro => {
        this.heldeskActionUpdate.next(null);
      });
  }

  confirmAccount(nim: string) {
    this.http.get<{ result: boolean }>(environment.APIURL + '/api/v4/heldesk/confirmaccount?nim=' + nim)
      .subscribe(response => {
        if (response) {
          this.heldeskActionUpdate.next({ status: response.result, nim, action: 'confirm' });
        } else {
          this.heldeskActionUpdate.next(null);
        }
      }, erro => {
        this.heldeskActionUpdate.next(null);
      });
  }

  breakAccount(nim: string) {
    this.http.get<{ result: boolean }>(environment.APIURL + '/api/v4/heldesk/break?nim=' + nim)
      .subscribe(response => {
        if (response) {
          this.heldeskActionUpdate.next({ status: response.result, nim, action: 'confirm' });
        } else {
          this.heldeskActionUpdate.next(null);
        }
      }, erro => {
        this.heldeskActionUpdate.next(null);
      });
  }

  save(data: any) {

    return this.http.put<{ result: boolean }>(
      environment.APIURL + '/api/v1/stores?nim=' + this.authService.currentNim, data);
  }
  saveChanges(data: any) {

    return this.http.post<{ result: boolean }>(
      environment.APIURL + '/api/v2/changes?nim=' + this.authService.currentNim, data);
  }
  loadChangesAll() {
    this.http.get<{ result: NewStatment[] }>(environment.APIURL + '/api/v2/changes?nim=' + this.authService.currentNim)
    .subscribe(response => {
      this.statementsUpdate.next(response.result || []);
    }, error => {
      this.statementsUpdate.next([]);
    });
  }
  loadChanges() {
    return this.http.get<{ result: NewStatment[] }>(environment.APIURL + '/api/v2/changes?nim=' + this.authService.currentNim);
  }
  valideChanges(changes: PostStatementAction[], actionValide: string) {
    return this.http.post<{ result: boolean }>(
      environment.APIURL + '/api/v2/changes/' + actionValide + '?nim=' + this.authService.currentNim, changes);
  }

  setIban(nim: string, iban: string, base64: string) {
    const url = `${environment.APIURL}/api/v1/stores/iban?nim=${nim}`;
    this.http.post(url, {IbanDocContent: base64, iban: iban})
      .subscribe(response => {
        this.ibanCheckWithMessageUpdate.next(200);
      }, erro => {
        this.ibanCheckWithMessageUpdate.next(erro.status);
      });
  }

  getSetIbanUpdateListener() {
    return this.ibanCheckWithMessageUpdate.asObservable();
  }

  validateIbanCode(nim: string, code: string) {
    const url = `${environment.APIURL}/api/v1/stores/iban/validate/${code}?nim=${nim}`;
    this.http.post(url, {})
      .subscribe(response => {
        this.ibanCheckUpdate.next(100);
      }, erro => {
        this.ibanCheckUpdate.next(101);
      });
  }

  keepExistingIban(nim: string) {
    const url = `${environment.APIURL}/api/v1/stores/iban/keepexisting?nim=${nim}`;
    this.http.put(url, {})
      .subscribe(response => {
        this.ibanCheckWithMessageUpdate.next(900);
      }, erro => {
        this.ibanCheckWithMessageUpdate.next(901);
      });
  }

  resendIbanCode(nim: string) {
    const url = `${environment.APIURL}/api/v1/stores/iban/code/resend?nim=${nim}`;
    this.http.post(url, {})
      .subscribe(response => {
        this.ibanCheckUpdate.next(200);
      }, erro => {
        this.ibanCheckUpdate.next(201);
      });
  }

  getIbanCheckUpdateListener() {
    return this.ibanCheckUpdate.asObservable();
  }
}
