import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject, Subscription } from 'rxjs';
import { AssortimentSimulationResult, StoreAssortimentResult, StoreAssortimentTitre } from '../Models/storeAssortiment.model';
import { AuthService } from '../services/auth-service';
import { StoreAssortimentService } from '../services/store-assortiment.service';
import { DtLanguages } from '../shared/dtLanguages';
import { ExportToCsv } from 'export-to-csv';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseupdateserviceComponent } from '../shared/baseupdateservice/baseupdateservice.component';
import { Sweetalert } from '../shared/sweetAlert';
import { UpdateserviceService } from '../services/updateservice.service';
import { GA } from '../shared/gAnalytics';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-assortimentlist',
  templateUrl: './assortimentlist.component.html',
  styleUrls: ['./assortimentlist.component.css']
})
export class AssortimentlistComponent extends BaseupdateserviceComponent implements OnInit, OnDestroy, AfterViewInit {

  private assotListSubs: Subscription;
  private simulationSubs: Subscription;
  private simulationValidationSubs: Subscription;
  private proposalFeedbackSubs: Subscription;
  assortResult: StoreAssortimentResult;
  filteredData: StoreAssortimentTitre[] = [];
  showSpinner = true;
  displayList = false;
  allRanksAreZero = false;

  searchMotif = '';
  searchFamily = '';
  searchCategory = '';
  searchStatus = '';
  type = 0;
  title = '';

  rangePercent = 0;
  rangeCA = 0;

  simulationResult: AssortimentSimulationResult;
  simulateLoading = false;

  validationLoading = false;
  feedbackLoading = false;

  changeCodif: Subject<string> = new Subject<string>();

  private DONE_SERVICE_KEY_PREFIX = 'assort-service';

  // datatable initialisations
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;

  constructor(protected updateService: UpdateserviceService,
    protected sweet: Sweetalert,
    private assortService: StoreAssortimentService,
    protected authService: AuthService,
    private dtlanguages: DtLanguages,
    protected router: Router,
    protected ngbModalService: NgbModal,
    protected ga: GA,
    private route: ActivatedRoute) {
      super(updateService, authService, router, ngbModalService, ga, sweet);
     }

    get displaySettings(): boolean {
      if (!this.assortResult) {
        return false
      }
      return this.assortResult.settings != null && !this.authService.isReadOnly();
    }

  ngOnInit() {
    super.ngOnInit();
    this.type = parseInt(this.route.snapshot.queryParams['type'], 0) || 0;
    const orderPosition = (this.type === 5 || this.type === 112 || this.type === 1102) ? [[5, 'desc'], [2, 'asc']] : [[2, 'asc'], [3, 'asc']];
    // datatable options
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 50,
      processing: true,
      searching: true,
      responsive: true,
      lengthChange: true,
      ordering: true,
      order: orderPosition,
      language: this.dtlanguages.frLanguage()
    };

    if (this.displayRank) {
      this.dtOptions['columnDefs'] = [{ 'searchable': false, 'targets': [1] }];
    }

    this.proposalFeedbackSubs = this.assortService.getserviceFeedbackOnProposalListener()
    .subscribe(result => {
      this.feedbackLoading = false;
      this.showSpinner = false;
      if (result && result.length) {
        this.rerender();
        this.filteredData = this.filteredData.filter(s => s.codif !== result);
        //this.refreshData();
      } else {
        this.sweet.warning('Attention !', 'Votre demande n‘a pas pu aboutir, veuillez essayer plus tard.');
      }
    });

    this.assotListSubs = this.assortService.getAssortimentTitresListener()
      .subscribe(result => {
        this.showSpinner = false;
        this.feedbackLoading = false;
        this.assortResult = result;
        this.filteredData = (result.titles || []);
        if (this.type === 9
          && result.settings
          && result.settings.ca
          && result.settings.ca.currentValue) {
          this.rangeCA = result.settings.ca.currentValue;
        }
        this.allRanksAreZero = this.filteredData.every(e => e.rank === 0);
        this.rerender();
      });

    this.simulationSubs = this.assortService.getAssortimentSimulationListener()
      .subscribe(result => {
        this.simulateLoading = false;
        result.list = result.list || [];
        if (result.manageQuantity) {
          result.list.forEach(i => i.userQuantity = 2);
        }

        this.simulationResult = result;
      });

    this.simulationValidationSubs = this.assortService.getAssortimentValidationListener()
      .subscribe(result => {
        this.validationLoading = false;
        this.showSpinner = false;
        this.displayList = false;
        if (result) {
          if (result.codifs && result.codifs.length) {
            result.codifs.forEach(r => localStorage.setItem(this.getCodifLineId(r), r));
          }
          if (result.status) {
            // tslint:disable-next-line:max-line-length
            let message = 'Votre demande a été prise en compte avec succès. Nous vous invitons à contrôler les titres ajoutés via la rubrique titres ajoutés de votre tableau de bord assortiment.';
            if (this.type === 5) {
              message = '';
            } else if (this.type === 4) {
              // tslint:disable-next-line:max-line-length
              message = 'Votre demande a été prise en compte avec succès. Nous vous invitons à contrôler les titres retirés via la rubrique titres retirés de votre tableau de bord assortiment.';
            }

            this.sweet.success('Félicitations !', message);
          }   else {
            this.sweet.error('Attention !', 'Une erreur est survenue lors de la validation de votre demande, veuillez contacter le support.');
          }
          this.closeModal();
        } else {
          this.sweet.warning('Attention !', 'Impossible de valider votre demande actuellement, veuillez réessayer ultérieurement SVP.');
        }
      });

      this.saveSubs = this.UpdateserviceRequest.getSaveUpdateListener()
          .subscribe(result => {
            this.showModalSpinner.next(false);
            if (result && result.status) {
              localStorage.setItem(this.getCodifLineId(this.titleCodif), this.titleCodif);
              this.gaEvent();
              this.goNext();
              //this.checkServiceProposal(result);
            }  else {
              this.sweet.error('Attention !', 'Une erreur est survenue lors de la validation de votre demande, veuillez contacter le support.');
            }
          });

    this.refreshData();
  }

  get displayLot2c(): boolean {
    return !environment.disabledLot2c;
  }

  getCodifLineId(codif: string): string {
    return `${this.DONE_SERVICE_KEY_PREFIX}-${codif}-${this.type}`;
  }

  isServiceDone(codif: string) {
    return (localStorage.getItem(this.getCodifLineId(codif)) || '').length > 0;
  }

  serviceForTitle(item: StoreAssortimentTitre) {
    if (!item) {
      return;
    }

    this.titleCodif = item.codif;
    this.openModal(this.modalService);
  }

  actionOnService(item: StoreAssortimentTitre, status: boolean) {
    if (this.feedbackLoading) {
      return;
    }
    if (!item) {
      return;
    }

    this.sweet.confirmDialog('Attention !',
    // tslint:disable-next-line:max-line-length
    'Êtes vous sûre de vouloir valider votre demande ?', (s, e) => {
      if (!s && !e) {
        this.showSpinner = false;
        return;
      }
      this.showSpinner = true;
      if (e) {
        // accept or refuse service.
        this.assortService.serviceFeedbackOnProposal(this.authService.currentNim, status, item);
      }
    });
  }

  private checkCurrentItemWithActiveSorting(position: number) {

    // get active sorting
    const order = this.dtElement['dt'].order()[0];
    const orderDirection = order[1] === 'desc' ? 0 : 1;
    let orderIndex = parseInt(order[0].toString(), 0);

    const keys = ['arrow', 'cover', 'rank', 'name', 'family', 'category', 'ca', 'unsoldRate', 'efficacityIndex', 'status', 'motif'];

    if (this.hideEfficacityIndex) {
      const indexOf = keys.indexOf('efficacityIndex');
      if (indexOf >= 0) {
        keys.splice(indexOf, 1);
      }
    }
    if (this.hideUnsoldRate) {
      const indexOf = keys.indexOf('unsoldRate');
      if (indexOf >= 0) {
        keys.splice(indexOf, 1);
      }
    }
    if(!this.displayRank) {
      const indexOf = keys.indexOf('rank');
      if (indexOf >= 0) {
        keys.splice(indexOf, 1);
      }
    }

    if(orderIndex <= 1 || keys.length <= orderIndex)
      return;

    const field = keys[orderIndex];
    const thenOrderByName = field !== "name";

    this.filteredData
      .sort((a, b) => {
        const aVal = a[field] || 0;
        const bVal = b[field] || 0;

        let compare = 0;
        if (typeof aVal === 'number') {
          if (orderDirection) {
            if (aVal > bVal) compare = 1;
            else if (aVal < bVal) compare = -1;
            else compare = a.name.localeCompare(b.name);
          } else {
            if (aVal < bVal) compare = 1;
            else if (aVal > bVal) compare = -1;
            else compare = a.name.localeCompare(b.name);
          }
        }
        else {
          compare = orderDirection ? aVal.localeCompare(bVal) : bVal.localeCompare(aVal);
          if(thenOrderByName) {
            compare = compare === 0 ? a.name.localeCompare(b.name) : compare;
          }
        }
        return compare;
      });

    const itemIndex = this.filteredData.findIndex(e => e.codif === this.titleCodif);
    if (itemIndex >= this.filteredData.length) {
      this.closeModal();
      return;
    }

    const item = this.filteredData[itemIndex + position];

    if (!item) {
      this.closeModal();
      return;
    }

    this.titleCodif = item.codif;
    this.changeCodif.next(this.titleCodif);
  }

  goNext() {
    this.checkCurrentItemWithActiveSorting(1);
  }

  goPrevious() {
    this.checkCurrentItemWithActiveSorting(-1);
  }

  get displayRank() {
    return this.type !== 5 
      && this.type !== 6 
      && this.type !== 9 
      && this.type !== 11
      && this.type !== 112
      && this.type !== 1102
      && !this.allRanksAreZero;
  }

  get displayPotentialCa() {
    return this.type === 5 || this.isProposal || this.type === 112 || this.type === 1102;
  }

  get hideUnsoldRate() {
    return this.type === 5 || this.isProposal || this.type === 112 || this.type === 1102;
  }

  get hideEfficacityIndex() {
    return this.type === 5 || this.isProposal || this.type === 112 || this.type === 1102;
  }

  get isProposal() {
    return this.type === 9;
  }

  get caHeaderName(): string {
    return (this.type === 5 || this.type === 9 || this.type === 112 || this.type === 1102) ? 'Vente potentielle / an' : 'Vente / an';
  }

  filterList() {
    if (!this.assortResult
      || !this.assortResult.titles
      || !this.assortResult.titles.length) {
      return;
    }

    this.filteredData = this.assortResult.titles;

    if (this.searchCategory.length) {
      this.filteredData = this.filteredData.filter(t => t.category === this.searchCategory);
    }

    if (this.searchFamily.length) {
      this.filteredData = this.filteredData.filter(t => t.family === this.searchFamily);
    }

    if (this.searchMotif.length) {
      this.filteredData = this.filteredData.filter(t => t.motif === this.searchMotif);
    }

    if (this.searchStatus.length) {
      this.filteredData = this.filteredData.filter(t => t.status === this.searchStatus);
    }

    this.rerender();
  }

  refreshData() {
    this.type = parseInt(this.route.snapshot.queryParams['type'], 0) || 0;
    switch (this.type) {
      case 1:
        this.title = 'Titres de droit';
        break;
      case 11:
      case 1101:
        this.title = 'Titres servis';
        break;
      case 112:
      case 1102:
        this.title = 'Titres non servis';
        break;
      case 12:
      case 2:
        this.title = 'Vos ajouts';
        break;
      case 1103:
        this.title = 'Vos quantités max';
        break;
      case 3:
        this.title = 'Titres NON CPPAP < seuil';
        break;
      case 4:
        this.title = 'Titres CPPAP/NON CPPAP > seuil';
        break;
      case 5:
        this.title = 'Titres disponibles';
        break;
      case 55:
        this.title = 'Premières présentations';
        break;
      case 6:
        this.title = 'Nouveautés';
        break;
      case 17:
      case 7:
        this.title = 'Vos retraits';
        break;
      case 8:
        this.title = 'Encyclopédies';
        break;
      case 9:
        this.title = 'Nos propositions';
        break;
      default:
        this.title = 'Palmarès de référence FRANCE MESSAGERIE';
        break;
    }
    this.searchFamily = '';
    this.searchStatus = '';
    this.searchCategory = '';
    this.searchMotif = '';

    this.simulationResult = null;
    const hasItems = this.filteredData.length;
    this.filteredData = [];
    if (hasItems) {
      this.rerender();
    }
    this.showSpinner = true;
    this.assortService.getAssortimentTitles(this.authService.currentNim, this.type);
  }

  resetSimulation() {
    this.simulationResult = null;
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  get disableForType4() {
    return this.type === 4;
  }

  // rerender the datatable
  rerender() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
    });
    this.dtTrigger.next();
  }

  exportDataToCSV() {
    if (!this.filteredData.length) {
      return;
    }

    let headers = [];
    if (this.displayRank) {
      headers = this.type === 112 ? 
            ['Rang', 'Codif', 'Libellé', 'Famille', 'Catégorie', this.caHeaderName, 'Exemplaires', 'Statut', 'Motif']
            : ['Rang', 'Codif', 'Libellé', 'Famille', 'Catégorie', this.caHeaderName, 'Exemplaires', 'Taux Invendus', 'Indice efficacité', 'Statut', 'Motif'];
    } else {
      headers = this.type === 112 ? 
            ['Codif', 'Libellé', 'Famille', 'Catégorie', this.caHeaderName, 'Exemplaires', 'Statut', 'Motif']
            : ['Codif', 'Libellé', 'Famille', 'Catégorie', this.caHeaderName, 'Exemplaires', 'Taux Invendus', 'Indice efficacité', 'Statut', 'Motif'];
    }
    const date = moment();
    const options = {
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      title: `${this.title}-${date.format('YYYY-MM-DD')}`,
      filename: `${this.title}-${date.format('YYYY-MM-DD')}`,
      headers: headers,
      useBom: true,
      useKeysAsHeaders: false
    };
    const csvExporter = new ExportToCsv(options);
    const data = this.filteredData.map((v, i) => {
      if (this.displayRank) {
        if(this.type === 112) {
          return { rang: v.rank, codif: v.codif, libelle: v.name, famille: v.family, categorie: v.category, ca: v.ca || '', quantite: v.soldQuantity, statut: v.status, motif: v.motif };
        }
        return { rang: v.rank, codif: v.codif, libelle: v.name, famille: v.family, categorie: v.category, ca: v.ca || '', quantite: v.soldQuantity, invendus: v.unsoldRate, efficacite: v.efficacityIndex, statut: v.status, motif: v.motif };
      }
      if (this.type === 112) {
        return { codif: v.codif, libelle: v.name, famille: v.family, categorie: v.category, ca: v.ca || '', quantite: v.soldQuantity, statut: v.status, motif: v.motif };  
      }
      // tslint:disable-next-line:max-line-length
      return { codif: v.codif, libelle: v.name, famille: v.family, categorie: v.category, ca: v.ca || '', quantite: v.soldQuantity, invendus: v.unsoldRate, efficacite: v.efficacityIndex, statut: v.status, motif: v.motif };
    });
    csvExporter.generateCsv(data);
  }

  isAllSelected(): boolean {
    return this.assortResult.families.findIndex(e => !e.selected) < 0;
  }

  onChangeFamily(event) {
    if (!event || !event.currentTarget) {
      return;
    }

    this.resetSimulation();

    this.assortResult.families.forEach(p => p.selected = event.currentTarget.checked);
  }

  getFamiliesToSimulate(): String[] {
    const families = this.assortResult.families.filter(s => s.selected);
    return families.map(s => s.name);
  }

  simulateAssort() {
    if (this.simulateLoading) {
      return;
    }
    this.simulateLoading = true;
    this.simulationResult = null;
    this.assortService.getAssortimentSimulation(this.authService.currentNim, this.type,
      {
        families: this.getFamiliesToSimulate(),
        caMin: this.rangeCA,
        efficacityMin: this.rangePercent
      });
  }

  private saveInstallation(message: string, clearFilteredData: boolean) {
    if (this.validationLoading) {
      return;
    }

    this.sweet.confirmDialog('Attention !',
    // tslint:disable-next-line:max-line-length
    message, (s, e) => {
      if (!s && !e) {
        this.validationLoading = false;
        this.showSpinner = false;
        return;
      }
      this.validationLoading = true;
      this.showSpinner = true;
      if (clearFilteredData) {
        const hasItems = this.filteredData.length;
        this.filteredData = [];
        if (hasItems) {
          this.rerender();
        }
      }
      if (e) {
        this.assortService.validateAssortimentSimulation(this.authService.currentNim, this.type,
          {
            families: this.getFamiliesToSimulate(),
            caMin: this.rangeCA,
            efficacityMin: this.rangePercent,
            list: (this.simulationResult && this.simulationResult.manageQuantity) ? this.simulationResult.list : []
          });
      }
    });
  }

  validateInstallation() {
    if (!this.simulationResult) {
      return;
    }
    // tslint:disable-next-line:max-line-length
    const message = `Vous allez installer ${this.simulationResult.count.value} titres, pour un chiffre d’affaire potentiel de ${this.simulationResult.ca.value} €. Souhaitez-vous valider ?`;
    this.saveInstallation(message, false);
  }

  removeSettings() {
    if (this.type !== 9) {
      return;
    }

    this.assortResult.families.forEach(s => s.selected = false);
    this.rangeCA = 0;
    this.rangePercent = 0;
    const message9 = `Êtes vous sûre de vouloir retirer vos préférences ?`;
    this.saveInstallation(message9, false);
  }

  validateSimulation() {
    if (this.validationLoading) {
      return;
    }

    if (this.type === 9) {
      const message9 = `Êtes vous sûre de vouloir continuer ?`;
      this.saveInstallation(message9, false);
    }

    if (!this.simulationResult) {
      return;
    }

    if (this.simulationResult.manageQuantity) {
      if (this.displayList) {
        this.validateInstallation();
        return;
      }
      this.displayList = true;
      return;
    }

    // tslint:disable-next-line:max-line-length
    let message = `Vous allez supprimer ${this.simulationResult.count.value} titres. Souhaitez-vous valider ?`;
    if (this.simulationResult.ca.value) {
      // tslint:disable-next-line:max-line-length
      message = `Vous allez supprimer ${this.simulationResult.count.value} titres. Vous renoncer à ${this.simulationResult.ca.value} € de Chiffre d’affaire. Souhaitez-vous valider ?`;
    }
    this.saveInstallation(message, false);
  }

  validateButtonText(): string {
    return this.type === 5 ? 'Suivant' : 'Valider';
  }

  displayValidate(): boolean {
    if (this.simulationResult) {
      return true;
    }

    return this.assortResult && this.assortResult.settings && !this.assortResult.settings.canSimulate;
  }

  goToSimulation() {
    this.displayList = false;
  }

  exportToCSV() {

    if (!this.simulationResult
      || !this.simulationResult.list
      || !this.simulationResult.list.length) {
      return;
    }

    const date = moment();
    const options = {
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: false,
      showTitle: false,
      title: `liste-titre-à-retirer-${date.format('YYYY-MM-DD')}`,
      filename: `liste-titre-à-retirer-${date.format('YYYY-MM-DD')}`,
      useBom: true,
      useKeysAsHeaders: true
    };
    const csvExporter = new ExportToCsv(options);
    const data = this.simulationResult.list.map((v, i) => {
        return {
          'Codif': v.codif || '',
          'Libellé': v.name || ''
        };
      });
    csvExporter.generateCsv(data);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.assotListSubs) {
      this.assotListSubs.unsubscribe();
    }
    if (this.simulationSubs) {
      this.simulationSubs.unsubscribe();
    }
    if (this.simulationValidationSubs) {
      this.simulationValidationSubs.unsubscribe();
    }
    if(this.proposalFeedbackSubs) {
      this.proposalFeedbackSubs.unsubscribe();
    }
  }

}
