import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { PdvbaseComponent } from '../pdvbase/pdvbase.component';
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CustomValidator } from 'src/app/shared/customValidator';
import { StoreService } from 'src/app/services/store.service';
import { Sweetalert } from 'src/app/shared/sweetAlert';
import { AuthService } from 'src/app/services/auth-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GA } from 'src/app/shared/gAnalytics';
import { DtLanguages } from 'src/app/shared/dtLanguages';
import { Store } from 'src/app/Models/store.model';
import * as moment from 'moment';

@Component({
  selector: 'app-rib',
  templateUrl: './rib.component.html',
  styleUrls: ['./rib.component.css']
})
export class RibComponent extends PdvbaseComponent implements OnInit, OnDestroy {
  ngOnDestroy(): void {
    if (this.setIbanSubscription) {
      this.setIbanSubscription.unsubscribe();
    }
    if (this.ibanSubscription) {
      this.ibanSubscription.unsubscribe();
    }
    if (this.storeSubscription) {
      this.storeSubscription.unsubscribe();
    }
  }


  ibanBase64 = '';
  ibanMessageError = '';
  changingIban = false;

  storeSubscription: Subscription;
  ibanSubscription: Subscription;
  setIbanSubscription: Subscription;

  @Output() refreshStore = new EventEmitter<Store>();

  changeIbanFormGroup: FormGroup;
  changeIbanCodeFormGroup: FormGroup;

  get displayRib() {
    if (!this.store)
      return false;

    if (!this.store.ibanInfo)
      return false;

    return this.store.ibanInfo.status >= 0;
  }

  get hasRib() {
    return this.displayRib && this.store.ibanInfo.status === 2;
  }

  get noRibProvided() {
    return this.displayRib && this.store.ibanInfo.status === 0 && !(this.store.ibanInfo.rib || '').length;
  }
  get pendingRib() {
    return this.displayRib && this.store.ibanInfo.status === 1;
  }

  get getExpiredate() {
    if(!this.store
      || !this.store.ibanInfo
      || !this.store.ibanInfo.expireDate
    ) {
      return '';
    }
    const dt = moment(this.store.ibanInfo.expireDate);
    if(!dt.isValid()) {
      return '';
    }
    return dt.format('DD-MM-YYYY');
  }

  get existingRib() {
    return this.displayRib && this.store.ibanInfo.status === 0 && (this.store.ibanInfo.rib || '').length;
  }

  ngOnInit() {
    this.changeIbanFormGroup = this.fb.group({
      txtIban: ['', [CustomValidator.ibanValidator]]
    });

    this.changeIbanCodeFormGroup = this.fb.group({
      txtCode: ['', [CustomValidator.ibanCodeValidator]]
    });

    this.ibanSubscription = this.storeService.getIbanCheckUpdateListener()
      .subscribe(result => {
        switch (result) {
          case 100:
            this.sweet.success('Félicitation !', 'Félicitation, votre adhésion est bien enregistrée.');
            // reload store info
            this.storeService.getStore(true);
            break;
          case 101:
            this.changingIban = false;
            this.sweet.error('Attention !', 'Attention, code de sécurité erroné.');
            break;
          case 200:
            // reload store info
            this.storeService.getStore(true);
            break;
          case 201:
            this.changingIban = false;
            this.sweet.error('Attention !', 'Attention, une erreur s‘est produite, veuillez réessayer plus tard !');
            break;
          default:
            this.sweet.error('Attention !', 'Attention, une erreur s‘est produite, veuillez réessayer plus tard !');
            break;
        }

      });

    this.storeSubscription = this.storeService.getStoreUpdateListner()
      .subscribe(result => {
        this.changingIban = false;
        if (result) {
          this.store = result;
        } else {
          this.sweet.error('Attention !', 'Une erreur s‘est produite lors du chargement des informations.');
        }
      });

    this.setIbanSubscription = this.storeService.getSetIbanUpdateListener()
      .subscribe(status => {
        this.changingIban = false;
        status = status || 0;

        switch (status) {
          case 400:
            this.sweet.error('Attention !', 'Attention l’IBAN saisi ne correspond pas au RIB déposé. Veuillez ressaisir l’IBAN.');
            break;
          case 409:
            this.sweet.warning('Attention !', 'Votre RIB est en cours de validation, merci de patienter SVP.');
            break;
          case 900:
            this.sweet.success('Félicitations !', 'Votre adhésion est bien enregistrée.');
            // reload store info
            this.storeService.getStore(true);
            break;
          case 901:
            this.sweet.warning('Attention !', 'Nous avons rencontré un problème lors de la prise en compte de votre demande.');
            break;
          case 200:
            this.sweet.success('Félicitation !', 'Votre RIB à bien été déposé. Nous vous envoyons par mail un code de sécurité à saisir, afin d’autoriser la transmission à France Messagerie');
            // reload store info
            this.storeService.getStore(true);
            break;
          default:
            this.sweet.warning('Attention !', 'Erreur technique !');
            break;
        }
      });

  }

  onFileSelect(input: HTMLInputElement) {

    const files = input.files;
    if (files && files.length) {
      const fileToRead = files[0];
      const fileReader = new FileReader();
      fileReader.onloadend = (e) => {
        // import form CSV
        this.ibanBase64 = e.target.result.toString();
      };
      fileReader.readAsDataURL(fileToRead);
    }
  }

  ibanChange(form: NgForm) {

    this.ibanMessageError = '';
    if (this.changingIban) {
      return;
    }

    if (!form.value.txtIban) {
      this.ibanMessageError = 'Merci de saisir votre IBAN.';
      return;
    }

    if (!this.ibanBase64
      || !this.ibanBase64.length) {
      this.ibanMessageError = 'Merci de fournir un relevé d‘identité bancaire.';
      return;
    }

    this.changingIban = true;
    this.sweet.confirmDialog('Attention !', 'Êtes-vous sûr de vouloir déposer votre relevé d‘identité bancaire ?',
      (err: Error, result: any): void => {
        if (!result && !err) {
          this.changingIban = false;
          return;
        }
        if (err) {
          this.changingIban = false;
          return;
        }
        this.storeService.setIban(this.authService.currentNim, form.value.txtIban, this.ibanBase64);
      });
  }

  ibanCodeChange(form: NgForm, event) {

    if (this.changingIban
      || !form.value.txtCode
      || !form.value.txtCode.length) {
      return;
    }

    this.changingIban = true;
    this.storeService.validateIbanCode(this.authService.currentNim, form.value.txtCode);
  }

  keepExitstingIban() {

    if (this.changingIban) {
      return;
    }

    this.changingIban = true;
    this.storeService.keepExistingIban(this.authService.currentNim);
  }

  removeExitstingIban() {

    this.store.ibanInfo.rib = '';
  }

  resendCode() {

    if (this.changingIban) {
      return;
    }

    this.sweet.confirmDialog('Attention !', 'Vous n‘avez pas reçu de code, vous souhaitez recevoir un nouveau ?',
      (err: Error, result: any): void => {
        if (!result && !err) {
          this.changingIban = false;
          return;
        }
        if (err) {
          this.changingIban = false;
          return;
        }
        this.changingIban = true;
        this.storeService.resendIbanCode(this.authService.currentNim);
      });
  }

  clickFile(input: HTMLElement, event) {

    event.preventDefault();
    input.click();
  }

  openModal(modal) {
    this.ngbModalService.open(modal);
  }

}
